import { MessageInstance } from 'antd/lib/message/interface';

import { CLIENT_API_PATH } from 'shared/routes';
import { isPasswordField } from 'shared/hashedOrEncryptedFields';
import sendPost from './sendPost';
import showConfigFormMessage from '../components/config/helpers/showConfigFormMessage';
import logger from '../logger';

export const hashEndpointURL = `${CLIENT_API_PATH}/hash_password`;
export const secretsEncryptEndpointURL = `${CLIENT_API_PATH}/encrypt_secret`;
export const configDecryptEndpointURL = `${CLIENT_API_PATH}/decrypt_config`;

function logError(msg: string, key?: string) {
  const action = key
    ? isPasswordField(key)
      ? 'hashing password'
      : 'encrypting secret'
    : 'decrypting config';
  const fullMsg = `Error while ${action}: ${msg}`;
  logger.warn(fullMsg);
  return fullMsg;
}

async function hashOrEncryptAPI(
  key: string,
  obj: Record<string, any>,
  algorithm?: string,
  message?: MessageInstance
): Promise<string> {
  // universal function to call the hashing or encryption endpoint & process the result
  let result = '';
  let err: string = '';
  const endpoint = isPasswordField(key) ? hashEndpointURL : secretsEncryptEndpointURL;
  try {
    const resp = await sendPost(endpoint, {
      str: obj[key],
      algorithm
    });
    const respData = await resp.json();
    if (resp.status === 200) {
      result = respData.result;
    } else err = logError(JSON.stringify(respData), key);
  } catch (e) {
    err = logError(`${e}`, key);
  }
  if (err && message) showConfigFormMessage(message, err, 'error', 15);
  return result;
}

export async function hashPasswordAPI(
  key: string,
  obj: Record<string, any>,
  algorithm: string,
  message?: MessageInstance
): Promise<string> {
  return await hashOrEncryptAPI(key, obj, algorithm, message);
}

export async function encryptSecretAPI(
  key: string,
  obj: Record<string, any>,
  message?: MessageInstance
): Promise<string> {
  return await hashOrEncryptAPI(key, obj, undefined, message);
}

export async function decryptConfig(
  obj: Record<string, any>,
  message?: MessageInstance
): Promise<Record<string, any> | undefined> {
  let resp;
  let err: string = '';
  try {
    resp = await sendPost(configDecryptEndpointURL, { ...obj });
    const respData = await resp.json();
    if (resp.status === 200) return respData.result;
    err = logError(JSON.stringify(respData));
  } catch (e) {
    err = logError(`${e}`);
  }
  if (err && message) showConfigFormMessage(message, err, 'error', 15);
}
