import React, { useEffect, useRef, useState } from 'react';
import { createBrowserRouter, RouterProvider, Navigate, RouteObject } from 'react-router-dom';
import { Spin, App as AntdApp } from 'antd';
import { HookAPI } from 'antd/lib/modal/useModal';
import Cookies from 'js-cookie';

import { APP_LOGIN_PATH, APP_CONFIG_PATH, APP_ONBOARDING_PATH } from 'shared/routes';
import { appLoginCookieName } from 'shared/cookies';
import type { LoginConfig } from 'shared/loginConfig';

import LoginPage from './pages/LoginPage';
import ConfigPage from './pages/config/ConfigPage';
import OnboardingPage from './pages/OnboardingPage';
import getLoginConfigHandler from './api/getLoginConfigHandler';
import showErrorModal from './lib/showErrorModal';
import logger from './logger';

import styles from './App.module.scss';

function splitPath(fullPath: string): [string, string] {
  const pathSplit = fullPath.split('/');
  return [`/${pathSplit[1]}`, pathSplit[2]];
}

export const localDev = process.env.NODE_ENV === 'development';
const testEnv = process.env.NODE_ENV === 'test';

// config for the login page
let loginConfig = JSON.parse(Cookies.get(appLoginCookieName) || '{}');
Cookies.remove(appLoginCookieName);

const basicRoute = (path: string, element: React.JSX.Element): RouteObject => {
  const [pathRoute, pathPageRoute] = splitPath(path);
  return {
    path: pathRoute, // auth | login
    children: [
      {
        path: pathPageRoute, // app_login | config | onboarding etc
        element: element
      }
    ]
  };
};

type PathPageTuple = [string, React.JSX.Element];

function createAppRouter(loginConfig: LoginConfig) {
  const routeTuples: PathPageTuple[] = [
    [APP_LOGIN_PATH, <LoginPage {...loginConfig} />],
    [APP_CONFIG_PATH, <ConfigPage />]
  ];
  if (loginConfig?.onBoardingRequired) {
    routeTuples.push([APP_ONBOARDING_PATH, <OnboardingPage />]);
  }

  const routes = routeTuples.map(([path, pageComponent]) => basicRoute(path, pageComponent));
  if (localDev || testEnv) {
    // redirecting back to the login page after logging in
    // + without adding testEnv here will get 404 in App tests
    routes.push({
      path: '/',
      children: [
        {
          path: '/',
          element: <Navigate to={APP_LOGIN_PATH} replace />
        }
      ],
      errorElement: <LoginPage {...loginConfig} />
    });
  }
  return createBrowserRouter(routes);
}

async function ensureLoginConfig(): Promise<LoginConfig> {
  // could do a check if the user is authorized and skip the login config retrieval
  if (!loginConfig || Object.keys(loginConfig).length === 0) {
    ({ loginConfig } = await getLoginConfigHandler());
  }
  return loginConfig;
}

function ensureOnboarding(loginConfig: LoginConfig, modal: HookAPI): void {
  // window.location.pathname !== APP_ONBOARDING_PATH after build, there's extra "/"
  const onBoardingPage = window.location.pathname.toString().startsWith(APP_ONBOARDING_PATH);
  if (!loginConfig?.onBoardingRequired || onBoardingPage) return;
  const redirectFn = () => window.location.replace(APP_ONBOARDING_PATH);
  showErrorModal('Onboarding required, will redirect you there...', modal, redirectFn, true);
}

function App() {
  const [loading, setLoading] = useState<boolean>(true);

  const { modal } = AntdApp.useApp();

  const hasEffectRun = useRef<boolean>(false);

  useEffect(() => {
    if (hasEffectRun.current) return;
    hasEffectRun.current = true;
    ensureLoginConfig().then((loginConfigUpd) => {
      logger.debug(`App | loginConfig: ${JSON.stringify({ loginConfig })}`);
      loginConfig = loginConfigUpd;
      ensureOnboarding(loginConfigUpd, modal);
      setLoading(false);
    });
  }, [modal]);

  if (loading) {
    // while we are loading, we show "dummy" login page just to look nicer
    return (
      <Spin
        size={'large'}
        spinning={loading}
        className={styles.app_spinner}
        data-testid="loginSpinner"
      >
        <LoginPage {...{}} />
      </Spin>
    );
  }

  return <RouterProvider router={createAppRouter(loginConfig)} />;
}

export default App;
