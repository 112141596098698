import logger from '../logger';

async function sendPost(endpointURL: string, reqBody: Record<string, any>) {
  const requestBody = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(reqBody)
  };
  logger.debug(`sendPost ${JSON.stringify({ endpointURL, reqBody })}`);
  return await fetch(endpointURL, requestBody);
}

export default sendPost;
