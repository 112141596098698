import React from 'react';
import { Flex, theme, Typography } from 'antd';
import clsx from 'clsx';

import defaultBranding from '../../lib/defaultBranding';
import { BrandingProps } from '../../types/afConfig';

import styles from './AppPageHeader.module.scss';

function AppPageHeader({ branding }: { branding?: BrandingProps }) {
  const { token } = theme.useToken();
  return (
    <Flex align="center" gap={24} style={{ height: '100%' }}>
      <div
        data-testid="custom-head-snippet"
        dangerouslySetInnerHTML={{
          __html: branding?.custom_head_snippet || ''
        }}
      />

      <Flex align="center" gap={24}>
        <img
          src={branding?.logo_url || defaultBranding.logo_url}
          alt={'Logo'}
          className={clsx(styles.logo, branding?.logo_url ? '' : ` ${styles.logo_default}`)}
        />
        <Typography.Title
          level={2}
          className={styles.title}
          style={{ color: token.colorWhite, margin: 0 }}
        >
          {branding?.title || defaultBranding.title}
        </Typography.Title>
      </Flex>
    </Flex>
  );
}

export default AppPageHeader;
