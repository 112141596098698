import { FormInstance } from 'antd';
import { ZodError } from 'zod';
import { FieldData } from 'rc-field-form/es/interface';
import { NamePath } from 'antd/es/form/interface';
import { MessageInstance } from 'antd/lib/message/interface';

import showConfigFormMessage from '../../config/helpers/showConfigFormMessage';
import scrollToAndFocusOnField from './scrollToAndFocusOnField';

type ZodPath = (string | number)[];
// indices are the numbers e.g. for complex objects it's strings & numbers mixed up

export function mapZodErrors(error: ZodError): FieldData[] {
  // ZodError<AuthfishConfig>[]
  return error.issues.flatMap((issue) => {
    // issue.path is ZodPath matching the NamePath with a small adjustment
    return mapErrors([zodPathToNamePathCompatible(issue.path)], issue.message);
  });
}

function mapErrors(fields: NamePath[], errorMsg: string): FieldData[] {
  return fields.map((field) => {
    return { name: field, errors: [errorMsg] };
  });
}

function showErrorMessage(msgInstance: MessageInstance, fieldData: FieldData) {
  // will format nicer if there will be such a need
  const errMsg = fieldData.errors?.join(', ') || 'unknown';
  const fName = Array.isArray(fieldData.name) ? fieldData.name.join('.') : fieldData.name;
  const fullMsg = `Error: ${errMsg} in ${fName}`;
  showConfigFormMessage(msgInstance, fullMsg, 'error', 10);
}

function zodPathToNamePathCompatible(zodPath: ZodPath): string[] {
  // stringify indices that could be numbers to make all Form Instance methods work as expected
  // e.g. Zod's ['auth', 'local', 'users', 0, 'username'] is 👌 for AntD's getFieldInstance
  // but it won't work with setFields we use for showing errors
  return zodPath.map((n: string | number) => n.toString());
}

function validateFieldNames(
  errMap: FieldData[],
  form: FormInstance,
  msgInstance?: MessageInstance
): FieldData[] {
  // checking if there's such a field in form
  return errMap
    .map((field) => {
      // first let's make sure we have such a field
      const fieldData = form.getFieldInstance(field.name);
      if (fieldData) return field;
      // if we couldn't find a field => show a message
      if (msgInstance) showErrorMessage(msgInstance, field);
      return undefined;
    })
    .filter(Boolean) as FieldData[];
}

export function showZodErrors(error: ZodError, form: FormInstance, msgInstance?: MessageInstance) {
  const errMap = mapZodErrors(error);
  // in certain cases, e.g. "password_hash" has actual input
  const errMapValidated: FieldData[] = validateFieldNames(errMap, form, msgInstance);
  form.setFields(errMapValidated);
  // and let's scroll to the first error & set focus there
  if (errMapValidated.length) scrollToAndFocusOnField(errMapValidated[0].name, form);
}

function showError(fields: string[], msg: string, form: FormInstance) {
  const errData = mapErrors(fields, msg);
  form.setFields(errData);
}

export default showError;
