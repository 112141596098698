import { HookAPI } from 'antd/lib/modal/useModal';

const reloadPage = () => window.location.reload();

function showErrorModal(
  errorMsg: string,
  modal: HookAPI,
  redirectFunc?: () => void,
  isWarning: boolean = false
) {
  const method = isWarning ? 'warning' : 'error';
  modal[method]({
    title: isWarning ? 'Warning' : 'Error',
    content: errorMsg,
    afterClose: redirectFunc || reloadPage
  });
}

export default showErrorModal;
