import type { AuthfishConfig } from './authfishConfig';
import serverURL from './serverURL';

// routes for React app
const AUTH_ROUTE = '/auth';
export const AUTH_ROUTER = `${AUTH_ROUTE}/`;
export const APP_LOGIN_PATH = '/login/app_login';
export const APP_CONFIG_PATH = `${AUTH_ROUTER}settings/`;
export const APP_ONBOARDING_PATH = `${AUTH_ROUTER}onboarding`; // '/auth/onboarding'

// API
export const CLIENT_API_PATH = '/client_api';

// moved from strategies/common since we'll need some of those at client
export const SUCCESS_ROUTE = '/';
export const LOGIN_ROUTE = `${AUTH_ROUTER}login`;
export const LOGOUT_ROUTE = `${AUTH_ROUTER}logout`;

export function fullLoginRoute(config?: AuthfishConfig): string {
  return `${config ? serverURL(config) : '...'}${LOGIN_ROUTE}`;
}

// SAML
export const SAML_LOGIN_ROUTE = `${AUTH_ROUTER}login-saml`;
export const SAML_LOGOUT_ROUTE = `${AUTH_ROUTER}logout-saml`;

export function fullSAMLLogoutURL(config?: AuthfishConfig) {
  return `${config ? serverURL(config) : '...'}${SAML_LOGOUT_ROUTE}`;
}

// OIDC
export const OIDC_LOGIN_ENDPOINT = '/login-oidc';
export const OIDC_CALLBACK_ENDPOINT = `${OIDC_LOGIN_ENDPOINT}/callback`;
export const OIDC_LOGOUT_ENDPOINT = '/logout-oidc';

export function defaultOIDCCallbackURL(config?: AuthfishConfig) {
  return `${config ? serverURL(config) : '...'}${AUTH_ROUTE}${OIDC_CALLBACK_ENDPOINT}`;
}
