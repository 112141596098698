/* eslint-disable @typescript-eslint/no-explicit-any */
import { userSchema } from './authfishConfig';
import type { AuthfishConfig } from './authfishConfig';
import { getLocalUsersSafe } from './getLocalUsers';

export type WithAuthLocalUsers = Pick<AuthfishConfig, 'auth'>;

function hasAuthLocalUsers(
  formValues: Record<string, any>,
  validateFirst = true
): formValues is WithAuthLocalUsers {
  const users = getLocalUsersSafe(formValues);
  if (!users || users.length === 0) return false;
  const valid = userSchema.safeParse(users[0]);
  return validateFirst ? valid.success : users[0].username !== undefined;
}

export default hasAuthLocalUsers;
