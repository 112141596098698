import type { AuthfishConfig } from './authfishConfig';
import type { ProtectedSettings } from './AuthfishParams';
import type User from './User';
import type { AuthfishStaticConfig } from './authfishStaticConfig';
import type { LoginConfig } from './loginConfig';
import isObject from './isObject';

export type ConfigSource = 'env' | 'db' | 'yml';

export interface getConfigResponse {
  config: AuthfishConfig;
  timestampsAvailable: string[]; // DbConfig.created_at to timestamp
  editPermission: boolean;
  protectedSettings: ProtectedSettings[];
  user: User | null;
  libraryMode: boolean;
  // for the library mode we don't need source & dbPath
  source?: ConfigSource;
  dbPath?: string;
  staticConfig: AuthfishStaticConfig;
  requiresOnboarding: string | null;
}

export function isGetConfigResponse(obj: object): obj is getConfigResponse {
  return 'config' in obj && 'editPermission' in obj;
}

export interface getLoginConfigResponse {
  loginConfig: LoginConfig;
}

export function isGetLoginConfigResponse(obj: object): obj is getLoginConfigResponse {
  return 'loginConfig' in obj && isObject(obj.loginConfig) && 'auth' in obj.loginConfig;
}

export interface saveConfigResponse {
  config: AuthfishConfig;
}

export function isErrorResponse(obj: object): obj is errorResponse {
  return 'error' in obj && !('config' in obj);
}

export interface errorResponse {
  error: string;
}
