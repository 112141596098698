import React, { useState, useEffect } from 'react';
import type { FormInstance } from 'antd';
import { Button, Form } from 'antd';

import logger from '../../logger';

type PreValidateCallBack = (form: FormInstance, values: Record<string, any>) => void;

interface submitButtonProps {
  form: FormInstance;
  caption?: string;
  dataTestID?: string;
  icon?: React.ReactNode;
  preValidateCallBack?: PreValidateCallBack;
  // extra element should have disabled prop
  ExtraElement?: React.ReactElement<{ disabled?: boolean }>;
}

const SubmitButton = ({
  form,
  caption = 'Submit',
  dataTestID = 'submitButton',
  icon = undefined,
  preValidateCallBack = undefined,
  ExtraElement = undefined
}: submitButtonProps) => {
  const [submittable, setSubmittable] = useState(false);

  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(() => {
    if (preValidateCallBack) preValidateCallBack(form, values);
    // could also run the full validation here using ZOD schema
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      (errors) => {
        logger.debug(`SubmitButton | validateFields errors: ${JSON.stringify(errors)}`);
        if (errors.outOfDate) {
          // logger.warn(errors);
          // to investigate WTF is this... smth w/ dep array ?
          return;
        }
        setSubmittable(false);
      }
    );
  }, [form, values, preValidateCallBack]);

  return (
    <>
      <Button
        type="primary"
        htmlType="submit"
        icon={icon}
        disabled={!submittable}
        data-testid={dataTestID}
      >
        {caption}
      </Button>
      {ExtraElement && React.isValidElement(ExtraElement)
        ? React.cloneElement(ExtraElement, { disabled: !submittable })
        : null}
    </>
  );
};

export default SubmitButton;
