import type { AuthfishConfig } from './authfishConfig';
import pathToURL from './pathToURL';
import { APP_CONFIG_PATH } from './routes';

function serverURL(config: AuthfishConfig): string {
  const protocol = config.https?.enabled ? 'https' : 'http';
  return `${protocol}://${config.host}:${config.port}`;
}

export function constructServerURL(config: AuthfishConfig, path: string): string {
  return pathToURL(serverURL(config), path);
}

export const CONFIG_URL = (config: AuthfishConfig) => constructServerURL(config, APP_CONFIG_PATH);

export default serverURL;
