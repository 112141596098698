import React from 'react';
import { Button, Form, FormListFieldData, Card } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { FormField } from '../../../types/FormFields';
import { itemKeyToName, keyForFormList } from './formKeys';
import FormDivider from '../../formElements/FormDivider';
import renderFormItem from './renderFormItem';
import { isPasswordField } from 'shared/hashedOrEncryptedFields';
import Passwords from '../Passwords';

import styles from '../../../pages/config/ConfigPage.module.scss';

function mapChildSchema(item: FormField, formListItemIndex: number): React.ReactNode {
  const fieldsMappings = item.childSchema;
  if (!fieldsMappings) return [];
  return fieldsMappings.map((fieldData: FormField) => {
    // we need to re-create the Key to match the dynamic structure with item index
    const updatedFieldData = {
      ...fieldData,
      key: keyForFormList(fieldData.key, formListItemIndex)
    };
    if (isPasswordField(updatedFieldData.key)) {
      return <Passwords item={updatedFieldData} key={`form_${updatedFieldData.key}`} />;
    } else {
      return renderFormItem(updatedFieldData);
    }
  });
}

function itemEntryName(item: FormField) {
  return item.label.endsWith('s') ? item.label.slice(0, -1) : 'Entry';
}

type AddCallBack = () => void;
type RemoveCallBack = (itemName: number) => void;

function formListItemDeleteButton(
  fields: FormListFieldData[],
  remove: RemoveCallBack,
  formListItemIndex: number
): React.ReactNode | null {
  if (fields.length <= 1) return null;
  return (
    <Button
      type="dashed"
      danger
      onClick={() => remove(formListItemIndex)}
      icon={<MinusCircleOutlined />}
      data-testid="configFormListDelete"
    >
      {`Delete`}
    </Button>
  );
}

function mapFormListFieldData(
  fields: FormListFieldData[],
  item: FormField,
  add?: AddCallBack,
  remove?: RemoveCallBack
): React.ReactNode {
  return fields.map((_, index) => {
    // FormListFieldData is so far useless; its props: key == "name" == index
    const lastItem = index === fields.length - 1;
    return (
      <Card key={`formItemInList__${index}`} className={styles.UserCard}>
        {mapChildSchema(item, index)}
        {remove && formListItemDeleteButton(fields, remove, index)}
        {lastItem && add && formListNewItemButton(item, add)}
      </Card>
    );
  });
}

function formListNewItemButton(item: FormField, addCallBack: AddCallBack): React.ReactNode {
  return (
    <Button
      type="dashed"
      onClick={() => addCallBack()}
      icon={<PlusOutlined />}
      data-testid="configFormListAdd"
    >
      {`Add new ${itemEntryName(item)}`}
    </Button>
  );
}

interface formListContentProps {
  item: FormField;
  fields: any;
  operations: { add: AddCallBack; remove: RemoveCallBack };
  errors: React.ReactNode[];
}

function FormListContent({
  item,
  fields,
  operations: { add, remove },
  errors
}: formListContentProps): React.JSX.Element {
  return (
    <>
      <FormDivider
        key={`${item.key}_divider`}
        label={item.label}
        dataTestID="configFormListDivider"
      />
      {mapFormListFieldData(fields, item, add, remove)}
      <Form.Item className={styles.form_list_errors}>
        <Form.ErrorList errors={errors} />
      </Form.Item>
    </>
  );
  // for now we don't use this ErrorList; could show errors here like so:
  // setFields => name: ['auth', 'local', 'users'] 📝 will require styling
}

function renderFormList(item: FormField): React.ReactNode {
  // 📝 that we compose the keys here + in keyForFormListItem we add the inner stuff
  return (
    <Form.List key={`${item.key}_formList`} name={itemKeyToName(item.key)}>
      {(fields, operations, meta) => (
        <FormListContent item={item} fields={fields} operations={operations} errors={meta.errors} />
      )}
    </Form.List>
  );
}

export default renderFormList;
