function pathToURL(baseURL: string, path: string): string {
  // baseURL should end with a slash if it has a path,
  // otherwise everything after domain will be dropped!
  if (!baseURL.endsWith('/')) baseURL += '/';
  if (path.startsWith('/')) path = path.slice(1);
  const url = new URL(path, baseURL);
  return url.toString();
}

export default pathToURL;
