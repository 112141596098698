import _ from 'lodash';
import { ZodError } from 'zod';
import { MessageInstance } from 'antd/lib/message/interface';

import { AuthfishConfig, configSchema } from 'shared/authfishConfig';
import removeEmptyEntries from './removeEmptyEntries';
import { mergeConfigsCustomizer } from 'shared/mergeConfigsCustomizer';
import hashOrEncrypt from './hashOrEncrypt';
import logger from '../logger';

type ConfigValidationResult = [AuthfishConfig, undefined] | [undefined, ZodError];

export function processFormValues(
  formValues: Record<string, unknown>,
  config: AuthfishConfig
): AuthfishConfig {
  const newValuesFiltered = removeEmptyEntries(formValues);
  const fullConfig: AuthfishConfig = _.cloneDeep(config);
  // since we remap the FormFields on Category change, need 2 merge
  // upd: merge combines elements so e.g. we can't remove items from arrays
  _.mergeWith(fullConfig, newValuesFiltered, mergeConfigsCustomizer);
  return fullConfig;
}

async function configEncryptedFromFormValues(
  formValues: Record<string, any>,
  config: AuthfishConfig,
  message?: MessageInstance
): Promise<ConfigValidationResult> {
  // note: returns the config with the passwords hashed & secrets encrypted (not for form)
  // we should process the form values first to set the password hashes
  const fullConfig = (await hashOrEncrypt(
    processFormValues(formValues, config),
    message
  )) as AuthfishConfig;
  const validated = configSchema.safeParse(fullConfig);
  if (validated.success) {
    logger.debug(`configEncryptedFromFormValues success: ${JSON.stringify(validated.data)}`);
    return [validated.data, undefined];
  } else {
    logger.info(JSON.stringify(fullConfig));
    logger.warn(`configEncryptedFromFormValues: ${validated.error}`);
    return [undefined, validated.error];
  }
}

export default configEncryptedFromFormValues;
