import React, { Dispatch, useEffect, useRef, useState } from 'react';
import type { InputRef } from 'antd';
import { App as AntdApp, Form, Input, RadioChangeEvent, Spin } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import clsx from 'clsx';

import { LoginFormFieldType } from '../types/FormFields';
import { authStrategies, AuthStrategies } from 'shared/authfishConfig';
import handleLogin, { handleLoginResponse } from '../api/handleLogin';
import SubmitButton from './formElements/SubmitButton';
import AuthTypesSelector, { authTypeCaption } from './AuthTypesSelector';
import styles from './LoginForm.module.scss';
import showError from './formElements/utilities/showError';
import validationRules from './formElements/utilities/validationRules';
import afLoginRedirect, { redirectMsg } from './config/helpers/afLoginRedirect';
import { authEndpoint } from '../api/handleLogin';
import logger from '../logger';

type SetLoadingCallable = Dispatch<any>;

function redirectToURL(
  url: string,
  setLoadingState?: SetLoadingCallable,
  val?: any,
  messageInstance?: MessageInstance,
  msgAddon?: string
) {
  if (messageInstance) {
    messageInstance.success(msgAddon ? `Redirecting to ${msgAddon}` : redirectMsg).then();
  }
  if (setLoadingState) setLoadingState(val);
  logger.debug(`redirecting to ${url}`);
  window.location.href = url;
}

type authTypesLoginRedirect = 'saml' | 'oidc';

interface LoginFormProps {
  local_enabled?: boolean;
  ldap_enabled?: boolean;
  saml_url?: string;
  oidc_enabled?: boolean;
  className?: string;
}

const LoginForm = ({
  local_enabled = true,
  ldap_enabled = false,
  saml_url = undefined,
  oidc_enabled = false,
  className
}: LoginFormProps) => {
  const defaultAuthType = local_enabled
    ? 'local'
    : ldap_enabled
      ? 'ldap'
      : saml_url
        ? 'saml'
        : oidc_enabled
          ? 'oidc'
          : 'local';

  // STATE, REFS & EFFECTS
  const [authType, setAuthType] = useState<AuthStrategies>(defaultAuthType);
  const [loading, setLoading] = useState<boolean>(false);
  const [shake, setShake] = useState<boolean>(false);

  const { message } = AntdApp.useApp();

  const usernameInput = useRef<InputRef>(null);

  // focusing on username on load & authType change | unlike Input.autoFocus
  useEffect(() => {
    usernameInput.current?.focus();
  }, [authType]);

  const useHasRedirected = () => {
    return useRef(false);
  };
  const hasRedirectedRef = useHasRedirected();

  const redirect2gateway = (target: authTypesLoginRedirect) => {
    const redirTarget = target === 'saml' ? (saml_url as string) : authEndpoint(target);
    redirectToURL(redirTarget, setLoading, !loading, message, `${target.toUpperCase()} gateway`);
  };

  const shouldRedirect = (authType: AuthStrategies): authType is authTypesLoginRedirect => {
    return (authType === 'saml' && saml_url !== undefined) || (authType === 'oidc' && oidc_enabled);
  };

  // early exit on initial load
  useEffect(() => {
    // if there's only SAML | OIDC, redirect to the provider; prevent double in Strict mode
    if (!hasRedirectedRef.current && shouldRedirect(defaultAuthType)) {
      hasRedirectedRef.current = true;
      redirect2gateway(defaultAuthType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAuthType]);

  // THE FORM
  const [form] = Form.useForm<LoginFormFieldType>();

  const loginInputRules = (fieldLabel: string) => {
    return validationRules(fieldLabel, 'text', true, 'your');
  };

  const processAPIResponse = async ({ success, redirectUrl, msg }: handleLoginResponse) => {
    if (success) {
      // normally can't see it locally though, 2 fast
      afLoginRedirect(redirectUrl as string, message);
    } else {
      if (msg?.toLowerCase().includes('network')) {
        message.error(msg, 10);
        return;
      }
      showError(['password'], msg || 'Unknown error, please try again!', form);
      setShake(true);
      setTimeout(() => setShake(false), 600);
    }
  };

  const onFinish = async (values: LoginFormFieldType) => {
    setLoading(true);
    const resp = await handleLogin(values);
    setLoading(false);
    await processAPIResponse(resp);
  };

  // COMPONENTS & LAYOUT

  const availableAuthTypes = authStrategies.filter(
    (type) =>
      (type === 'local' && local_enabled) ||
      (type === 'ldap' && ldap_enabled) ||
      (type === 'saml' && saml_url) ||
      (type === 'oidc' && oidc_enabled)
  );

  const authTypeOnChange = (e: RadioChangeEvent) => {
    const target = e.target.value;
    if (shouldRedirect(target)) redirect2gateway(target);
    setAuthType(e.target.value);
  };

  const authTypesSelProps = {
    authTypesEnabled: availableAuthTypes, // will add "key" to re-render no change
    currentAuthType: authType,
    onChange: authTypeOnChange
  };

  return (
    <Form
      form={form}
      name="login"
      className={clsx(styles.login_form, shake ? ' shake' : '', className)}
      initialValues={{ authType: defaultAuthType }}
      onFinish={onFinish}
    >
      <Spin size={'large'} spinning={loading}>
        <Form.Item<LoginFormFieldType>
          style={availableAuthTypes.length > 1 ? { textAlign: 'center' } : { visibility: 'hidden' }}
          name="authType"
        >
          <AuthTypesSelector
            key={`auth-types-${availableAuthTypes.length}`}
            {...authTypesSelProps}
          />
        </Form.Item>

        <Form.Item<LoginFormFieldType>
          name="username"
          rules={loginInputRules('username')}
          className={styles.input_item}
        >
          <Input
            placeholder="Username"
            name="username"
            ref={usernameInput}
            autoComplete="username"
            // autoFocus
          />
        </Form.Item>

        <Form.Item<LoginFormFieldType>
          name="password"
          rules={loginInputRules('password')}
          className={styles.input_item}
        >
          <Input.Password placeholder="Password" name="password" autoComplete="off" />
        </Form.Item>

        <Form.Item style={{ textAlign: 'center' }}>
          <SubmitButton
            form={form}
            caption={`Login with ${authTypeCaption(authType, true)}`}
            dataTestID={'loginButton'}
          />
        </Form.Item>
      </Spin>
    </Form>
  );
};

export default LoginForm;
