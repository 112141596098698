import React from 'react';

import { authProps, BrandingProps } from '../types/afConfig';
import LoginForm from '../components/LoginForm';
import styles from './shared/WelcomePage.module.scss';
import WelcomePage from './shared/WelcomePage';
import defaultBranding from '../lib/defaultBranding';

interface LoginProps {
  auth?: authProps;
  branding?: BrandingProps;
}

const LoginPage = ({ auth, branding }: LoginProps) => {
  // let's make local login true by default for local development
  const localDev = process.env.NODE_ENV === 'development';
  const localEnabled = auth ? auth.strategies.includes('local') : localDev;
  const ldapEnabled = auth ? auth.strategies.includes('ldap') : false;
  const samlEntryPoint = auth?.strategies.includes('saml') ? auth.saml_redirect : undefined;
  const oidcEnabled = auth ? auth.strategies.includes('oidc') : false;
  const loginFormProps = {
    local_enabled: localEnabled,
    ldap_enabled: ldapEnabled,
    saml_url: samlEntryPoint,
    oidc_enabled: oidcEnabled
  };
  return (
    <WelcomePage
      branding={branding}
      pageTitle={'Login'}
      pageSubTitle={branding?.subtitle || defaultBranding.subtitle}
    >
      <LoginForm {...loginFormProps} className={styles.form} />
    </WelcomePage>
  );
};

export default LoginPage;
