import React from 'react';
import { App as AntdApp, FormInstance, Layout, Spin } from 'antd';
import clsx from 'clsx';

import { CONFIG_URL } from 'shared/serverURL';
import { APP_CONFIG_PATH } from 'shared/routes';
import WelcomePage from './shared/WelcomePage';
import useConfigLoader from '../hooks/useConfigLoader';
import ConfigForm from '../components/config/ConfigForm';
import patchConfig from '../api/patchConfig';
import { afLoginDefaultRedirect } from '../components/config/helpers/afLoginRedirect';

import styles from './OnboardingPage.module.scss';
import configStyles from './config/ConfigPage.module.scss';

const { Content } = Layout;

const OnboardingPage = () => {
  const { modal, message } = AntdApp.useApp();

  const { config, setConfig, staticConfig, loading, sourceData } = useConfigLoader(modal);

  if (!config || !staticConfig) return <></>;

  const configPatched = patchConfig(config);

  const headingInfo = {
    title: 'Onboarding',
    instructions: (
      <>
        <p>
          <b>Please set a password for the superuser</b>, so that you can log in to the system. Feel
          free adding more users if you need to.
        </p>
        <p>
          Later on you can change any settings, manage the users, etc on the{' '}
          <a href={CONFIG_URL(config)}>{APP_CONFIG_PATH}</a> page.
        </p>
      </>
    )
  };

  const onFormReadyCallable = (form: FormInstance) => {
    form.getFieldInstance(['auth', 'local', 'users', '0', 'password_hash__0'])?.focus();
  };

  return (
    <WelcomePage pageTitle={'Onboarding'}>
      <Content className={clsx(configStyles.content, styles.content)}>
        <Spin size={'large'} spinning={loading}>
          {configPatched && (
            <ConfigForm
              config={configPatched}
              staticConfig={staticConfig}
              setConfig={setConfig}
              activeLabel={'Local'}
              configSourceObj={sourceData} // need this for proper hints about path etc when save
              onFinishExtraCallable={afLoginDefaultRedirect(message)}
              headingInfo={headingInfo}
              onFormReadyCallable={onFormReadyCallable}
            />
          )}
        </Spin>
      </Content>
    </WelcomePage>
  );
};

export default OnboardingPage;
