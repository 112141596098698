import { MessageInstance } from 'antd/lib/message/interface';

import { APP_LOGIN_PATH } from 'shared/routes';

export const redirectMsg = 'Success! Redirecting...';

function afLoginRedirect(redirectURL: string, messageInstance?: MessageInstance): void {
  if (messageInstance) messageInstance.success(redirectMsg).then();
  // we'll handle the cookies on the server
  window.location.href = redirectURL;
}

export function afLoginDefaultRedirect(messageInstance?: MessageInstance) {
  return () => afLoginRedirect(APP_LOGIN_PATH, messageInstance);
}

export default afLoginRedirect;
