/* setting up global Logger | use like:
 * import logger from './logger';
 * logger.debug(`MyComponent is rendered ${...}`);
 * logger.log('smth'); // etc
 *  */

import log, { LogLevelDesc } from 'loglevel';
import _ from 'lodash';

function isLogLevelDesc(logLevel?: string | number): logLevel is LogLevelDesc {
  const logLevels = ['trace', 'debug', 'info', 'warn', 'error', 'silent'];
  const numericLogLevels = _.range(0, logLevels.length + 1);
  return [...logLevels, ...numericLogLevels].includes(logLevel || '');
}

type NodeEnv = 'development' | 'production' | 'test';

function getLoglevel(env: NodeEnv | ''): LogLevelDesc {
  let defaultLoglevel: LogLevelDesc = 'warn'; // prod
  switch (env) {
    case 'test':
      defaultLoglevel = 'silent';
      break;
    case 'development':
      defaultLoglevel = 'info';
      break;
  }
  const logLevelENV = process.env.REACT_APP_LOG_LEVEL;
  if (isLogLevelDesc(logLevelENV)) {
    return logLevelENV;
  }
  return defaultLoglevel;
}

// will need only warns for prod & just errs for tests | local INFO by def
const logLevel = getLoglevel((process.env.NODE_ENV as NodeEnv) || '');
log.setLevel(logLevel);
console.log(`App log level set to "${logLevel}"`);

const logger = log;
export default logger;
