import React, { useMemo, useState } from 'react';
import { getActivationKey } from '../../../api/handleActivationKey';
import { suspend } from 'suspend-react';
import { Button, Card, Col, Collapse, Row, Typography } from 'antd';
import styles from './ActivationKeyManagement.module.scss';
import { LoadActivationKeyModal } from './LoadActivationKeyModal';
import { DeleteActivationKeyModal } from './DeleteActivationKeyModal';
import { NotifyAndLogoutModal } from './NotifyAndLogoutModal';
import { ActivationKeyOrigin } from 'shared/activationKey';
import { configSourceData } from '../../../api/getConfigHandler';
import { AuthfishStaticConfig } from 'shared/authfishStaticConfig';

export function toDate(n: number): Date {
  return new Date(n * 1000);
}

function withRedirectParam(url: string) {
  const newUrl = new URL(url);
  newUrl.searchParams.set('redirect', window.location.href);

  return newUrl.toString();
}
interface Props {
  staticConfig: AuthfishStaticConfig;
  configSourceObj: configSourceData | undefined;
}

export const ActivationKeyManagement = ({ staticConfig, configSourceObj }: Props) => {
  const envVariableCode = `
  export AUTHFISH_ACTIVATION_KEY=<your activation key>
  `;

  const [showLoadActivationModal, setShowLoadActivationModal] = useState(false);
  const [showDeleteActivationModal, setShowDeleteActivationModal] = useState(false);
  const [showNotifyAndLogoutModal, setShowNotifyAndLogoutModal] = useState(false);

  const handleCloseLoadActivationModal = () => {
    setShowLoadActivationModal(false);
  };
  const handleOpenLoadActivationModal = () => {
    setShowLoadActivationModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteActivationModal(false);
  };
  const handleOpenDeleteModal = () => {
    setShowDeleteActivationModal(true);
  };

  const handleOpenNotifyAndLogoutModal = () => {
    setShowNotifyAndLogoutModal(true);
  };

  const { data: activationKey } = suspend(getActivationKey);

  const difference_ms = useMemo(
    () => toDate(activationKey.exp).getTime() - new Date().getTime(),
    [activationKey.exp]
  );
  const difference_days = useMemo(
    () => Math.round(difference_ms / (1000 * 3600 * 24)),
    [difference_ms]
  );

  const items = useMemo(() => {
    const availableItems = [
      { title: 'Activation Key ID', description: activationKey.jti },
      { title: 'Edition', description: activationKey.license.edition_name },
      { title: 'Licensed to', description: activationKey.licensee.name }
    ];
    if (activationKey.licensee.buying_for) {
      availableItems.push({ title: 'Buying for', description: activationKey.licensee.buying_for });
    }
    availableItems.push(
      { title: 'Created', description: `${toDate(activationKey.iat).toDateString()}` },
      {
        title: 'Expires',
        description: `in ${difference_days} days (${toDate(activationKey.exp).toDateString()})`
      },
      { title: 'Loaded from', description: activationKey.origin ?? '-' }
    );

    return availableItems;
  }, [
    difference_days,
    activationKey.exp,
    activationKey.iat,
    activationKey.jti,
    activationKey.license.edition_name,
    activationKey.licensee.buying_for,
    activationKey.licensee.name,
    activationKey.origin
  ]);

  const isDeleteKeyActivationKeyDisabled = useMemo(() => {
    const validOrigins: ActivationKeyOrigin[] = ['Api'];

    return !validOrigins.includes(activationKey.origin);
  }, [activationKey.origin]);

  const collapseAboutItems = useMemo(
    () => [
      {
        key: '1',
        label: 'About activation keys',
        children: (
          <>
            <Typography.Title level={5}>What is an activation key?</Typography.Title>
            <Typography.Paragraph>
              An activation key is a special string of characters that contains encoded information
              about your license and should be treated like a password. It includes details about
              your license type, expiration date, and licensee information. Like any credential, it
              should be kept secure and not shared with unauthorized parties.
            </Typography.Paragraph>
            <Typography.Title level={5}>How to obtain an activation key?</Typography.Title>
            <Typography.Paragraph>
              You can obtain an activation key by either{' '}
              <Typography.Link
                href={withRedirectParam(
                  staticConfig.activation_key.active_subscriber_activation_key_url
                )}
                target="_blank"
              >
                purchasing a license
              </Typography.Link>{' '}
              or getting a{' '}
              <Typography.Link
                href={withRedirectParam(staticConfig.activation_key.trial_url)}
                target="_blank"
              >
                trial key
              </Typography.Link>
              . You will receive your activation key via email. This key is required to unlock some
              extra features in the software.
            </Typography.Paragraph>
          </>
        )
      }
    ],
    [
      staticConfig.activation_key.active_subscriber_activation_key_url,
      staticConfig.activation_key.trial_url
    ]
  );

  const collapseFooterItems = useMemo(
    () => [
      {
        key: '1',
        label: 'Other ways to activate',
        children: (
          <>
            <Typography.Paragraph>
              Alternative, non-interactive ways to load the activation key into the software.
            </Typography.Paragraph>

            <Typography.Title level={5}>1. Via Environmental variable</Typography.Title>
            <Typography.Paragraph>
              This method is useful for Docker deployments. Just set the{' '}
              <code>AUTHFISH_ACTIVATION_KEY</code>
              environment variable to the activation key you obtained.
              <pre>
                <Typography.Text code>{envVariableCode}</Typography.Text>
              </pre>
            </Typography.Paragraph>

            <Typography.Title level={5}>2. Via text file</Typography.Title>
            <Typography.Paragraph>
              You can add a small text file with your activation key to this archive before
              installing it, so it will be picked up automatically at the first boot.
              <ul>
                <li>
                  The text file should be called <code>ACTIVATION_KEY.txt</code>
                </li>
                <li>
                  It should contain your secret activation key string (no spaces, no new lines)
                </li>
                <li>It should be added to the root directory of the software.</li>
              </ul>
            </Typography.Paragraph>
          </>
        )
      }
    ],
    [envVariableCode]
  );

  return (
    <>
      <Collapse defaultActiveKey={['1']} items={collapseAboutItems}></Collapse>

      <div style={{ marginTop: '4rem' }}>
        <Card
          title="Currently loaded activation key"
          bordered={true}
          style={{
            backgroundColor: '#f8f9fa',
            borderColor: '#1890ff',
            borderWidth: '1px',
            borderStyle: 'solid',
            boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
          }}
        >
          <div className={styles.activation_key_wrapper}>
            {items.map((item, index) => (
              <Row gutter={16} key={index} className={styles.activation_key_row}>
                <Col span={6}>
                  <Typography.Text strong>{item.title}:</Typography.Text>
                </Col>
                <Col span={18}>
                  <Typography.Text>{item.description}</Typography.Text>
                </Col>
              </Row>
            ))}
          </div>

          <Row gutter={12} style={{ marginTop: '1.5rem' }}>
            <Col>
              <Button type="primary" onClick={handleOpenLoadActivationModal}>
                Load another
              </Button>
            </Col>
            <Col>
              <Button
                danger
                disabled={isDeleteKeyActivationKeyDisabled}
                onClick={handleOpenDeleteModal}
              >
                Load default
              </Button>
            </Col>
          </Row>
        </Card>
      </div>

      <div style={{ marginTop: '4rem' }}>
        <Collapse items={collapseFooterItems}></Collapse>
      </div>

      <LoadActivationKeyModal
        open={showLoadActivationModal}
        handleClose={handleCloseLoadActivationModal}
        handleNotifyAndLogout={handleOpenNotifyAndLogoutModal}
        configSourceObj={configSourceObj}
      />
      <DeleteActivationKeyModal
        open={showDeleteActivationModal}
        handleClose={handleCloseDeleteModal}
        handleNotifyAndLogout={handleOpenNotifyAndLogoutModal}
        configSourceObj={configSourceObj}
      />
      <NotifyAndLogoutModal
        open={showNotifyAndLogoutModal}
        message={'Activation Key successfully changed'}
      />
    </>
  );
};
