import React, { useState, useCallback } from 'react';
import { Layout, App as AntdApp, Spin } from 'antd';

// may move this to the index | App if will need other pages inside iFrame
import 'iframe-resizer/js/iframeResizer.contentWindow.js';

import SiderComponent from '../../components/config/SiderComponent';
import { FieldCategories } from '../../types/FormFields';
import PageHeader from '../shared/PageHeader';
import ConfigForm from '../../components/config/ConfigForm';
import UserMenu from '../../components/config/UserMenu';
import useConfigLoader from '../../hooks/useConfigLoader';

import styles from './ConfigPage.module.scss';

const { Sider, Content } = Layout;

const ConfigPage = () => {
  const [activeLabel, setActiveLabel] = useState<FieldCategories>('General');

  const { modal } = AntdApp.useApp();

  const onItemClickHandler = useCallback((label: FieldCategories) => {
    setActiveLabel(label);
  }, []);

  const {
    config,
    setConfig,
    staticConfig,
    user,
    timestampsAvailable,
    setTimestampsAvailable,
    protectedSettings,
    sourceData,
    loading
  } = useConfigLoader(modal);

  if (!config || !staticConfig) return <></>;

  const addTimestamp = (newTimestamp: number): void => {
    setTimestampsAvailable((prevTimestamps: number[]): number[] =>
      prevTimestamps.includes(newTimestamp) ? prevTimestamps : [newTimestamp, ...prevTimestamps]
    );
  };

  // light theme should be also applied in SiderComponent Menu
  return (
    <>
      <PageHeader pageTitle={'Config'} branding={config?.branding} />
      <Layout className={styles.layout}>
        <Sider className={styles.sider} theme={'light'}>
          <SiderComponent
            onItemClickHandler={onItemClickHandler}
            protectedSettings={protectedSettings}
          />
          <UserMenu user={user} />
        </Sider>
        <Layout>
          <Content className={styles.content}>
            <Spin size={'large'} spinning={loading}>
              {config && (
                <ConfigForm
                  config={config}
                  staticConfig={staticConfig}
                  setConfig={setConfig}
                  activeLabel={activeLabel}
                  timestampsAvailable={timestampsAvailable}
                  addTimestamp={addTimestamp}
                  protectedSettings={protectedSettings}
                  configSourceObj={sourceData}
                />
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default ConfigPage;
