import _ from 'lodash';

import { AuthfishConfig, AuthStrategies, UserSchema } from 'shared/authfishConfig';

function patchConfig(config: AuthfishConfig): AuthfishConfig {
  const configCopy = _.cloneDeep(config);
  const superUser: UserSchema = {
    username: 'admin',
    password_hash: '',
    algorithm: 'sha512',
    roles: ['superuser']
  };
  // could check what exactly is wrong with the config, but for now
  // this should be the most effective approach
  return {
    ...configCopy,
    auth: {
      ...configCopy.auth,
      strategies: ['local'] as AuthStrategies[],
      local: { ...configCopy.auth.local, users: [superUser] }
    }
  };
}

export default patchConfig;
