import { AuthfishConfig } from 'shared/authfishConfig';
import {
  getConfigResponse,
  saveConfigResponse,
  errorResponse,
  isGetConfigResponse
} from 'shared/responses';
import { CLIENT_API_PATH } from 'shared/routes';
import sendPost from './sendPost';
import UnauthorizedError from './UnauthorizedError';
import { handleError, handleResponse, AuthfishResponse } from './responses';
import logger from '../logger';

export const configEndpointURL = `${CLIENT_API_PATH}/config`;
export const serverReadyEndpointURL = `${CLIENT_API_PATH}/restart_ok`;

type ConfigResponse = getConfigResponse | saveConfigResponse | errorResponse;

export async function getConfig(createdAtTS?: string): Promise<AuthfishResponse<ConfigResponse>> {
  // tests in ConfigPage.test.tsx
  const getURL = createdAtTS ? `${configEndpointURL}?created_at=${createdAtTS}` : configEndpointURL;
  try {
    const response = await fetch(getURL);
    return await handleResponse(response);
  } catch (error: unknown) {
    return handleError(error);
  }
}

export async function awaitServerReady(): Promise<boolean> {
  let msg = 'saveConfig: server is not ready, re-trying...';
  let respStatus = 0;
  try {
    const serverReadyResp = await fetch(serverReadyEndpointURL);
    respStatus = serverReadyResp.status;
    if (respStatus === 200) return true;
  } catch (error: unknown) {
    msg = `saveConfig: ${error}, re-trying...`;
  }
  logger.warn(msg);
  if (respStatus === 401) {
    // means smth off; no point re-trying
    throw new UnauthorizedError(respStatus.toString());
  }
  await new Promise((resolve) => setTimeout(resolve, 5000));
  return awaitServerReady();
}

async function tryUpdateTimestamps(addTimestamp: (timestamp: number) => void) {
  let getConfigResp;
  try {
    ({ response: getConfigResp } = await getConfig());
  } catch (error: unknown) {}
  if (getConfigResp && isGetConfigResponse(getConfigResp)) {
    // here we'll get the config before the current, since the latest one
    // won't be in timestampsAvailable => makes no sense to restore the same
    addTimestamp(parseInt(getConfigResp.timestampsAvailable[0]));
  }
}

export async function saveConfig(
  config: AuthfishConfig,
  addTimestamp: (timestamp: number) => void,
  libraryMode: boolean = true
): Promise<AuthfishResponse<ConfigResponse>> {
  // tests in ConfigPage.test.tsx
  try {
    await awaitServerReady(); // in case there was a page reload or smth
    const response = await sendPost(configEndpointURL, config);
    // turned out this ↘️️ causes problems when used with Next in dev mode
    if (!libraryMode) await awaitServerReady();
    else await new Promise((resolve) => setTimeout(resolve, 5000));
    await tryUpdateTimestamps(addTimestamp);
    return await handleResponse(response);
  } catch (error: unknown) {
    return handleError(error);
  }
}
