import { CLIENT_API_PATH } from 'shared/routes';

import { AuthfishResponse, handleError, handleResponse } from './responses';
import { errorResponse, getLoginConfigResponse, isGetLoginConfigResponse } from 'shared/responses';
import logger from '../logger';

const endpointURL = `${CLIENT_API_PATH}/login_config`;

type LoginConfigResponse = getLoginConfigResponse | errorResponse;

async function getLoginConfig(): Promise<AuthfishResponse<LoginConfigResponse>> {
  try {
    const response = await fetch(endpointURL);
    return await handleResponse(response);
  } catch (error: unknown) {
    // shouldn't happen since that's public endpoint
    return handleError(error);
  }
}

async function getLoginConfigHandler() {
  const { success, response } = await getLoginConfig();
  logger.debug(`getLoginConfigHandler ${JSON.stringify({ success, response })}`);
  if (success && isGetLoginConfigResponse(response)) {
    return response;
  }
  return { loginConfig: null };
}

export default getLoginConfigHandler;
