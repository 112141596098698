/* eslint-disable @typescript-eslint/no-explicit-any */
import type { WithAuthLocalUsers } from './hasAuthLocalUsers';
import type { UserSchemaWithPasswords } from 'client/src/types/afConfig';
import type { UserSchema } from './authfishConfig';

import _ from 'lodash';

function cloneUsersFiltered(userEntries: Record<string, any>): UserSchema[] {
  // when we just add a new entry, it could be undefined initially
  return _.cloneDeep(userEntries).filter((u: unknown) => u);
}

export function getLocalUsersSafe(formValues: Record<string, any>): UserSchema[] {
  const userEntries = formValues.auth?.local?.users || [];
  return cloneUsersFiltered(userEntries);
}

function getLocalUsers(formValues: WithAuthLocalUsers): UserSchemaWithPasswords[] {
  const userEntries = formValues.auth.local?.users || [];
  return cloneUsersFiltered(userEntries);
}

export default getLocalUsers;
